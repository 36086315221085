<template>
  <div class="login">
    <div class="container">
      <div class="row">
        <div class="col-sm-12 col-md-8 col-lg-6 mx-auto">
          <img
            class="mx-auto img-fluid mt-5"
            alt="Vue logo"
            src="@/assets/images/horiz_logo.png"
          />
          <form @submit.prevent="handleLogin">
            <div class="form-group">
              <label for="exampleInputEmail1" class="mt-3 text-">{{
                $t("emailaddress")
              }}</label>
              <input
                type="email"
                v-model="user.email"
                class="form-control"
                id="exampleInputEmail1"
                aria-describedby="emailHelp"
                
              />
            </div>
            <div class="form-group">
              <label for="exampleInputPassword1" class="mt-3">{{
                $t("password")
              }}</label>
              <input
                type="password"
                v-model="user.password"
                class="form-control"
                id="exampleInputPassword1"
                
              />
            </div>
            <button
              type="submit"
              class="btn btn-lg btn-dark bg-dark bg-gradient col-12 mt-3"
            >
              {{ $t("logar") }} <i class="bi bi-box-arrow-in-right"></i>
            </button>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { getCurrentInstance } from "vue";
// import UserService from "@/services/user.service";
export default {
  name: "Login",
  setup() {
    const app = getCurrentInstance();
    const user = {};
    app.appContext.config.globalProperties.$store
      .dispatch("auth/isLoged")
      .then((initialState) => {
        if (
          initialState.status.loggedIn &&
          initialState.user &&
          initialState.user.id
        ) {
          // console.log("initialState");
          // console.log(initialState);
          app.appContext.config.globalProperties.$router.push("/");
          // UserService.getLoged().then((result) => {
          //   console.log(result);
          //   if (result.status >= 200 && result.status < 400) {
          //     app.appContext.config.globalProperties.$router.push("/");
          //   } else {
          //     app.appContext.config.globalProperties.$store.dispatch(
          //       "auth/logout"
          //     );
          //   }
          // });
        }
      });
    const handleLogin = () => {
      if (user.email && user.password) {
        app.appContext.config.globalProperties.$store
          .dispatch("auth/login", user) 
          .then(
            () => {
              app.appContext.config.globalProperties.$router.go(0);
            },
            (error) => {
              console.log(error);
            }
          );
      }
    };
    return { user, handleLogin };
  },
};
</script>
